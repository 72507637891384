import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { palettes } from "@/common/constants/palettes";

export default makeStyles()((theme: Theme) => ({
  bottomNavigation: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    height: 72,
    zIndex: 1240,
    backgroundColor: theme.mode.background.popover,
    boxShadow: theme.mode.shadow.bottomBar,
    borderRadius: 16,
    gap: theme.spacing(0.75),
    padding: theme.spacing(0, 2),
  },
  avatar: {
    width: 32,
    height: 32,
  },
  icon: {
    width: "2rem",
    height: "2rem",
    fill: "#BEBEC5",
    color: "#BEBEC5",
  },
  bottomNavigationAction: {
    padding: 0,
    minWidth: "unset",
  },
  iconSelected: {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  badge: {
    border: `3px solid ${theme.mode.background.popover}`,
    width: 24,
    height: 24,
    borderRadius: "50%",
    top: "14%",
    right: "14%",
    backgroundColor: palettes.red.primary.main,
  },
  badgeInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "2.45em",
    height: "2.45em",
  },
}));
