import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  item: {
    padding: theme.spacing(1.5, 0),
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    cursor: "pointer",
    "&:hover": {
      background: theme.mode.background.lighter,
    },
    "&:first-of-type": {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1.5, 2),
    },

    "& .moreIcon": {
      position: "absolute",
      display: "none",
      transition: "all 300ms",
      "&.hover": {
        display: "block",
      },
      "& .iconButton": {
        boxShadow: theme.mode.shadow.popover,
        background: theme.mode.background.light,
        padding: "0px !important",
        width: 32,
        height: 32,
        transition: "box-shadow 200ms",
        " svg": {
          fill: theme.mode.icon.default,
        },

        "&:hover": {
          opacity: 1,
          boxShadow: "0 0 16px 6px rgba(63, 61, 86, 0.08)",
        },
      },
    },
  },
  content: {
    flex: "1",
    fontSize: "0.9375rem",
    "& b": {
      fontWeight: "bold",
    },
    color: theme.mode.text.heading,
  },
  avatar: {
    position: "relative",
  },
  timestamp: {
    marginTop: "2px",
    fontWeight: "bold",
    fontSize: "0.7rem",
    display: "flex",
    justifyContent: "space-between",
    "& button": {
      marginTop: "8px",
    },
  },
  statusRead: {
    display: "none",
  },
  unreadStatus: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  paginationLink: {
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    border: 0,
    background: 0,
    fontWeight: 800,
    fontFamily: theme.typography.fontFamily,
    display: "block",
    margin: theme.spacing(3, "auto", 0),
    cursor: "pointer",
    textAlign: "center",
  },
  paginationLinkBottomMargin: {
    marginBottom: theme.spacing(3),
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "4px",
  },
  emptyWrapper: {
    padding: "16px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "400px",
  },
  emptyTitle: {
    fontSize: "1.3125rem",
    lineHeight: "normal",
    fontWeight: "bold",
    margin: "40px 0 0 0",
    color: theme.mode.text.heading,
  },
  emptySubtitle: {
    fontSize: "1.0625rem",
    lineHeight: "normal",
    margin: "8px 0 16px 0",
    color: theme.mode.text.heading,
  },
  emptyCta: {
    fontSize: "1.0625rem",
    lineHeight: "normal",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textDecoration: "none",
    margin: 0,
  },
}));

export default useStyles;
