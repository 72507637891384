import { useRouter } from "next/router";
import React, { useEffect, useState, HTMLAttributes } from "react";

import { Badge, BottomNavigation, BottomNavigationAction } from "@mui/material";

import TourStep from "@/tour/components/TourStep";

import ChatIcon from "@/common/icons/ChatIcon";
import { CommunityIcon } from "@/common/icons/CommunityIcon";
import { HamburgerIcon } from "@/common/icons/HamburgerIcon";
import HomeIcon from "@/common/icons/HomeIcon";

import { useUnreadChatMessagesStore } from "@/chat/hooks";

import { BottomBarActions } from "@/common/models/AppBarActions";
import { matchRoute, shortenedBadgeValue } from "@/common/services";
import { useFlag } from "@/feature-flags/contexts/FlagsContext";

import useStyles from "./BottomBar.styles";

type ActionProps = {
  value: BottomBarActions;
  icon: (props: HTMLAttributes<SVGSVGElement>) => JSX.Element;
  link?: string;
  exact?: boolean;
  related?: string[];
  disabled?: boolean;
  unselectable?: boolean;
};

const getActions = (disabledActions: BottomBarActions[]): ActionProps[] => [
  {
    value: BottomBarActions.Home,
    icon: HomeIcon,
    link: "/",
    exact: true,
    related: ["/community", "/news"],
  },
  {
    value: BottomBarActions.Community,
    icon: CommunityIcon,
    link: "/community",
    exact: true,
  },
  {
    value: BottomBarActions.Chat,
    icon: ChatIcon,
    link: "/chat",
    exact: true,
    disabled: disabledActions.includes(BottomBarActions.Chat),
  },
  {
    value: BottomBarActions.Menu,
    icon: HamburgerIcon,
  },
];

export type BottomBarProps = {
  onAction?: (value: BottomBarActions) => void;
  menuIsOpen?: boolean;
};

type BottomBarBadgeValues = {
  [key: number]: number | string;
};

type BottomBarActionIconWrapperProps = {
  children: JSX.Element;
  badgeValue?: number | string;
};

const BottomBarActionIconWrapper = ({ children, badgeValue }: BottomBarActionIconWrapperProps) => {
  const { classes } = useStyles();

  return badgeValue ? (
    <Badge
      badgeContent={badgeValue}
      color="secondary"
      overlap="circular"
      classes={{ badge: classes.badge }}>
      <div className={classes.badgeInner}>{children}</div>
    </Badge>
  ) : (
    children
  );
};

export const BottomBar = ({ onAction, menuIsOpen }: BottomBarProps) => {
  const router = useRouter();
  const [actions, setActions] = useState<ActionProps[]>(getActions([BottomBarActions.Chat]));
  const [selected, setSelected] = useState<ActionProps>(actions[0]);
  const { classes, cx } = useStyles();
  const { unreadMessagesCount } = useUnreadChatMessagesStore();
  const { enabled: chatEnabled, loading: chatEnabledLoading } = useFlag("FE_CHAT");

  const badgeValues: BottomBarBadgeValues = {
    [BottomBarActions.Chat]: shortenedBadgeValue(unreadMessagesCount),
  };

  const handleChange = (_event: unknown, action: ActionProps) => {
    if (action.disabled) return;

    setSelected(action);
    onAction && onAction(action.value);

    if (action.link) {
      setTimeout(() => {
        router.push(action.link as string);
      }, 200);
    }
  };

  useEffect(() => {
    if (!chatEnabledLoading && chatEnabled) {
      setActions(getActions([]));
    }
  }, [chatEnabledLoading]);

  useEffect(() => {
    if (menuIsOpen) return;
    const action = matchRoute(actions, router.pathname);
    setSelected(action);
  }, [router.pathname, actions, menuIsOpen]);

  return (
    <BottomNavigation
      classes={{ root: classes.bottomNavigation }}
      value={selected}
      onChange={handleChange}>
      {actions.map((action) =>
        action.disabled ? null : (
          <BottomNavigationAction
            key={action.value}
            classes={{
              root: classes.bottomNavigationAction,
            }}
            value={action}
            icon={
              <TourStep
                show={action.value === BottomBarActions.Menu}
                topic="discover"
                step={1}
                render={(props) => (
                  <BottomBarActionIconWrapper badgeValue={badgeValues[action.value]}>
                    <action.icon
                      className={cx(classes.icon, {
                        [classes.iconSelected]: selected === action,
                      })}
                      {...props}
                    />
                  </BottomBarActionIconWrapper>
                )}
              />
            }
          />
        ),
      )}
    </BottomNavigation>
  );
};
