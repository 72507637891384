import React from "react";

import { makeStyles } from "tss-react/mui";

import UserAvatar from "@/common/components/UserAvatar";

import { MainIcon, SystemNotificationFragment } from "@/graphql/types";

import { SystemTargetIcon } from "./SystemTargetIcon";

const useStyles = makeStyles()(() => ({
  avatar: {
    position: "relative",
  },
}));

const SystemNotificationIcon = ({ notification }: { notification: SystemNotificationFragment }) => {
  const { classes } = useStyles();

  if (notification.mainIcon === MainIcon.User) {
    return (
      <div
        onClick={() => {
          notification.targetLink && window?.location.assign(notification.targetLink);
        }}
        className={classes.avatar}>
        <UserAvatar user={notification?.author} hidePresence />
        <SystemTargetIcon notification={notification} />
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        notification.targetLink && window?.location.assign(notification.targetLink);
      }}
      className={classes.avatar}>
      <SystemTargetIcon notification={notification} large />
    </div>
  );
};

export default SystemNotificationIcon;
