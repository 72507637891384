import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  nav: {
    position: "fixed",
    left: 0,
    top: 84,
    width: 282,
    height: "100%",
    borderRadius: 0,
    zIndex: theme.zIndex.appBar + 10,
    backgroundColor: theme.mode.background.light,
  },
  mobileNav: {},
  navHeader: {
    display: "grid",
    gridTemplateColumns: "40px 1fr 40px",
    columnGap: theme.spacing(1),
    padding: theme.spacing(1, 3),
  },
  navProfileAvatar: {
    width: 40,
    height: 40,
    cursor: "pointer",
    backgroundColor: "#BDBDBD",
  },
  navProfileInfo: {
    display: "flex",
    flexDirection: "column",
  },
  navProfileLabel: {
    fontSize: "1.0625rem",
    fontWeight: "bold",
    lineHeight: "1.4375rem",
    color: theme.mode.text.heading,
  },
  navProfileCaption: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    lineHeight: "1.0625rem",
    color: "#a9a9b2",
  },
  closeButton: {
    backgroundColor: theme.mode.icon.backgroundSecondary,
    height: "auto",
  },
  navItems: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: theme.spacing(3),
    },
  },
  navItem: {
    borderRadius: 32,
    margin: theme.spacing(1.25, 3),
    padding: theme.spacing(1.5, 2),
    width: "auto",
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    gap: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: theme.mode.background.default,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      margin: 0,
      gap: 0,
      backgroundColor: theme.mode.background.default,
      borderRadius: 16,
    },
  },
  navItemIcon: {
    width: "2rem",
    height: "2rem",
    color: "inherit",
    [theme.breakpoints.down("md")]: {
      width: "1.75rem",
      height: "1.75rem",
    },
  },
  navItemLabel: {
    fontSize: "1.3125rem",
    lineHeight: "1.5rem",
    fontWeight: 800,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9375rem",
      lineHeight: 1.33,
      letterSpacing: "0.025rem",
    },
  },
  secondaryNavItems: {
    paddingTop: theme.spacing(1),
  },
  secondaryNavItem: {
    fontSize: "1.0625rem",
    fontWeight: 600,
    padding: theme.spacing(1.5, 3),
    gap: theme.spacing(1),
    color: theme.mode.text.description,
  },
  secondaryNavItemIcon: {
    color: "inherit",
    width: "1.5rem",
    height: "1.5rem",
  },
  secondaryNavItemLabel: {
    fontSize: "1.0625rem",
    fontWeight: 600,
    color: "inherit",
  },
  appAnouncment: {
    width: `calc(100% - ${theme.spacing(6)})`,
    padding: theme.spacing(2.5, 2),
    margin: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 16,
    display: "grid",
    gridTemplateColumns: "40px 1fr",
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(0.75),
    alignItems: "center",
  },
  appAnouncmentIcon: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  appAnouncmentTitle: {
    fontSize: "1.3125rem",
    fontWeight: 800,
  },
  appAnouncmentDescription: {
    fontSize: "1.0625rem",
    fontWeight: 600,
    gridColumn: "span 2",
  },
  appStoreLinks: {
    display: "flex",
    padding: "12px 24px",
    gap: "15px",
  },
  link: {
    cursor: "pointer",
  },
}));
