import React from "react";

import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";

const useStyles = makeStyles()(() => ({
  actions: {
    display: "flex",
    gap: "8px",
  },
}));

import { useNotificationsStore } from "@/notifications/hooks";

import {
  ActionButtonTypes,
  NotificationActionButton,
  SystemNotificationFragment,
} from "@/graphql/types";

export const SystemNotificationButtons = ({
  notification,
}: {
  notification: SystemNotificationFragment;
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { answerNotification, answerNotificationLoading } = useNotificationsStore();

  const handleNotificationAnswerClick = (button: NotificationActionButton) => {
    // check if link button
    switch (button.type) {
      case ActionButtonTypes.Action:
        if (typeof button.actionValue === "boolean") {
          answerNotification(notification.id, button.actionValue);
        }
      case ActionButtonTypes.Link:
        button.url && window?.location.assign(button.url);
      default:
        return;
    }
  };

  const parseLabel = (label: string) => {
    switch (label.toLowerCase()) {
      case "accept":
        return t("Notifications.Actions.Accept");
      case "decline":
        return t("Notifications.Actions.Decline");
      default:
        return label;
    }
  };

  return !notification.answered ? (
    <div className={classes.actions}>
      {notification.leftButton && (
        <Button
          size="small"
          disabled={answerNotificationLoading}
          variant="secondary"
          uppercase
          onClick={() =>
            notification.leftButton && handleNotificationAnswerClick(notification.leftButton)
          }
          data-cy="SystemNotification_leftButton">
          {parseLabel(notification.leftButton.label)}
        </Button>
      )}
      {notification.rightButton && (
        <Button
          size="small"
          disabled={answerNotificationLoading}
          uppercase
          onClick={() =>
            notification.rightButton && handleNotificationAnswerClick(notification.rightButton)
          }
          data-cy="SystemNotification_rightButton">
          {parseLabel(notification.rightButton.label)}
        </Button>
      )}
    </div>
  ) : null;
};
