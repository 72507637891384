import React, { SVGProps } from "react";

type SpinnerProps = SVGProps<SVGSVGElement> & {
  variant?: "extraSmall" | "small" | "medium" | "large";
  color?: string;
};

function Spinner(props: SpinnerProps) {
  const variant = props.variant || "medium";
  const fillColor = props.color ? props.color : "#b9b9b9";
  const variantSizeMap = {
    extraSmall: 20,
    small: 25,
    medium: 50,
    large: 100,
  };
  const size = variantSizeMap[variant];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      {...props}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <g transform="translate(80,50)">
        <g transform="rotate(0)">
          <circle
            cx="0"
            cy="0"
            r="6"
            fill={fillColor}
            fillOpacity="1"
            transform="scale(1.32226 1.32226)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.8571428571428571s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.8571428571428571s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(68.704694055762,73.4549444740409)">
        <g transform="rotate(51.42857142857143)">
          <circle
            cx="0"
            cy="0"
            r="6"
            fill={fillColor}
            fillOpacity="0.8571428571428571"
            transform="scale(1.39369 1.39369)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.7142857142857143s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.7142857142857143s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(43.32437198131057,79.2478373654547)">
        <g transform="rotate(102.85714285714286)">
          <circle
            cx="0"
            cy="0"
            r="6"
            fill={fillColor}
            fillOpacity="0.7142857142857143"
            transform="scale(1.46512 1.46512)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.5714285714285714s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.5714285714285714s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(22.97093396292743,63.01651217352675)">
        <g transform="rotate(154.2857142857143)">
          <circle
            cx="0"
            cy="0"
            r="6"
            fill={fillColor}
            fillOpacity="0.5714285714285714"
            transform="scale(1.03655 1.03655)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.42857142857142855s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.42857142857142855s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(22.970933962927425,36.98348782647326)">
        <g transform="rotate(205.71428571428572)">
          <circle
            cx="0"
            cy="0"
            r="6"
            fill={fillColor}
            fillOpacity="0.42857142857142855"
            transform="scale(1.10798 1.10798)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.2857142857142857s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.2857142857142857s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(43.32437198131056,20.752162634545293)">
        <g transform="rotate(257.1428571428571)">
          <circle
            cx="0"
            cy="0"
            r="6"
            fill={fillColor}
            fillOpacity="0.2857142857142857"
            transform="scale(1.1794 1.1794)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.14285714285714285s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.14285714285714285s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(68.704694055762,26.545055525959103)">
        <g transform="rotate(308.5714285714286)">
          <circle
            cx="0"
            cy="0"
            r="6"
            fill={fillColor}
            fillOpacity="0.14285714285714285"
            transform="scale(1.25083 1.25083)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="0s"></animate>
          </circle>
        </g>
      </g>
    </svg>
  );
}

export default Spinner;
