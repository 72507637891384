import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  targetIcon: {
    boxSizing: "border-box",
    position: "absolute",
    bottom: "-8px",
    right: "-8px",
    padding: "4px",
    background: theme.palette.primary.main,
    borderRadius: "50%",
    fill: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
  },
  targetIconLarge: {
    position: "static",
    padding: "8px",
  },
}));

import ArticlesIcon from "@/common/icons/ArticlesIcon";
import CalendarIcon from "@/common/icons/CalendarIcon";
import ConversationIcon from "@/common/icons/ConversationIcon";
import CrownIcon from "@/common/icons/CrownIcon";
import GroupsIcon from "@/common/icons/GroupsIcon";
import HeartIcon from "@/common/icons/HeartIcon";
import PetitionsIcon from "@/common/icons/PetitionsIcon";
import PoliticallLogoShort from "@/common/icons/PoliticallLogoShort";
import VerifiedIcon from "@/common/icons/VerifiedIcon";
import VideoIcon from "@/common/icons/VideoIcon";

import { MainIcon, SecondaryIcon, SystemNotificationFragment } from "@/graphql/types";

export const SystemTargetIcon = ({
  notification,
  large,
}: {
  notification: SystemNotificationFragment;
  large?: boolean;
}) => {
  const { classes, cx } = useStyles();

  const iconsProps = {
    width: large ? "40" : "24",
    height: large ? "40" : "24",
    className: cx(classes.targetIcon, large && classes.targetIconLarge),
  };

  if (!large) {
    switch (notification.secondaryIcon) {
      case SecondaryIcon.Group:
        return <GroupsIcon {...iconsProps} />;
      case SecondaryIcon.Debate:
        return <ConversationIcon {...iconsProps} />;
      case SecondaryIcon.Event:
        return <CalendarIcon {...iconsProps} />;
      case SecondaryIcon.Blog:
        return <ArticlesIcon {...iconsProps} />;
      case SecondaryIcon.Petition:
        return <PetitionsIcon {...iconsProps} />;
      case SecondaryIcon.LiveStream:
        return <VideoIcon {...iconsProps} />;
    }
  }

  switch (notification.mainIcon) {
    case MainIcon.Group:
      return <GroupsIcon {...iconsProps} />;
    case MainIcon.Debate:
      return <ConversationIcon {...iconsProps} />;
    case MainIcon.Event:
      return <CalendarIcon {...iconsProps} />;
    case MainIcon.Blog:
      return <ArticlesIcon {...iconsProps} />;
    case MainIcon.Petition:
      return <PetitionsIcon {...iconsProps} />;
    case MainIcon.LiveStream:
      return <VideoIcon {...iconsProps} />;
    case MainIcon.Politicall:
      return <PoliticallLogoShort {...iconsProps} />;
    case MainIcon.Verification:
      return <VerifiedIcon {...iconsProps} />;
    case MainIcon.Subscription:
      return <CrownIcon usePrimary {...iconsProps} />;
    case MainIcon.Donation:
      return <HeartIcon usePrimary {...iconsProps} />;
    default:
      return <PoliticallLogoShort {...iconsProps} />;
  }
};
