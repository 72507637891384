import React, { ReactNode } from "react";

import { SwipeableDrawer } from "@mui/material";

import useStyles from "./Drawer.styles";

export interface DrawerProps {
  open: boolean;
  className?: string;
  direction?: "right" | "left" | "top" | "bottom";
  onOpen: () => void;
  onClose: () => void;
  children: ReactNode;
}

export const Drawer = ({ open, className, direction, onOpen, onClose, children }: DrawerProps) => {
  const { classes, cx } = useStyles();

  return (
    <SwipeableDrawer
      anchor={direction}
      keepMounted
      classes={{ paper: cx(classes.drawer, className) }}
      open={open}
      onClose={onClose}
      onOpen={onOpen}>
      {children}
    </SwipeableDrawer>
  );
};
