import React, { useEffect } from "react";

import { Dialog, DialogTitle, DialogContent, Theme, useMediaQuery, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import { Actions } from "@/notifications/components/Actions";
import { NotificationsList } from "@/notifications/components/NotificationsList/NotificationsList";

import NotificationsOffIcon from "@/common/icons/NotificationsOffIcon";

import { useOneSignalContext } from "@/common/providers";

import { useNotificationsStore } from "../hooks";

interface NotificationsPanelProps {
  open: boolean;
  onClose: (result?: any) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  dialog: {
    backgroundColor: theme.mode.background.light,
    borderRadius: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      overflow: "hidden",
      position: "fixed",
      top: 88,
      right: 8,
      margin: 0,
      maxHeight: "calc(100% - 120px)",
      boxShadow: theme.mode.shadow.dialog,
    },
  },
  headerRoot: {
    padding: theme.spacing(2, 2.25),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    padding: theme.spacing(0, 0.25, 3, 0.25),
    minWidth: "380px",
  },
  title: {
    display: "flex",
    flexGrow: "1",
    fontSize: "1.5rem",
    fontWeight: 800,
    lineHeight: "normal",
    color: theme.mode.text.heading,
  },
  section: {
    marginBottom: "8px",
  },
  heading: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  notificationBellIcon: {
    "& path": {
      fill: "#7d7d8c",
      color: "#7d7d8c",
    },
  },
  notificationButton: {
    margin: "0px 12px",
    minHeight: "32px !important",
    fontSize: "0.6875rem",
    padding: "8px 13px",
    textTransform: "uppercase",
  },
  notificationOffButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 12px",
    borderRadius: "50%",
    height: "38px",
    width: "38px",
    fontSize: "0.6875rem",
    backgroundColor: "rgba(63, 61, 86, 0.12)",
    ":hover": {
      cursor: "pointer",
      backgroundColor: "rgba(63, 61, 86, 0.09)",
    },
  },
  noMargin: {
    margin: "0",
  },
}));

const NotificationsPanel = ({ open, onClose }: NotificationsPanelProps) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { showPrompt, isSubscriptionOn, removeSubscription } = useOneSignalContext();

  const { totalSystemNotificationsCount, fetchSystemNotifications, markAllAsRead } =
    useNotificationsStore();

  useEffect(() => {
    if (open) {
      fetchSystemNotifications();
      markAllAsRead();
    }
  }, [open]);

  return (
    <Dialog
      fullScreen={isMobile}
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={onClose}
      aria-labelledby="notifications-dialog"
      BackdropProps={{ style: { backgroundColor: "transparent" } }}>
      <DialogTitle classes={{ root: classes.headerRoot }}>
        <>
          <div className={classes.header}>
            <h1 className={classes.title}>{t("Notifications.Title")}</h1>
            {!isSubscriptionOn && (
              <Button
                className={cx(classes.notificationButton, {
                  [classes.noMargin]: totalSystemNotificationsCount === 0,
                })}
                onClick={showPrompt}>
                {t("Notifications.Settings.EnableNotifications")}
              </Button>
            )}
            {isSubscriptionOn && (
              <Tooltip
                key={"notification_off"}
                enterDelay={250}
                placement="left"
                title={t("Notifications.Settings.DisableNotificationsTooltip") || ""}
                arrow>
                <div
                  className={cx(classes.notificationOffButton, {
                    [classes.noMargin]: totalSystemNotificationsCount === 0,
                  })}
                  onClick={removeSubscription}>
                  <NotificationsOffIcon className={classes.notificationBellIcon} />
                </div>
              </Tooltip>
            )}
            {totalSystemNotificationsCount > 0 && <Actions />}
          </div>
        </>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }} id="scrollableDiv">
        <NotificationsList variant="small" hidePaginationLinks />
      </DialogContent>
    </Dialog>
  );
};

export default NotificationsPanel;
