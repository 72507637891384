import React from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  wrapper: {
    position: "absolute",
    overflow: "hidden",
    clip: "rect(0 0 0 0)",
    height: "1px",
    width: "1px",
    margin: "-1px",
    padding: "0",
    border: "0",
  },
}));

export const VisuallyHidden = ({ children, ...delegated }: { children: any }) => {
  const { classes } = useStyles();
  const [forceShow, setForceShow] = React.useState(false);

  React.useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      const handleKeyDown = (event: any) => {
        if (event.key === "Alt") {
          setForceShow(true);
        }
      };

      const handleKeyUp = () => {
        setForceShow(false);
      };

      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
      };
    }
  }, []);

  if (forceShow) {
    return children;
  }

  return (
    <div className={classes.wrapper} {...delegated}>
      {children}
    </div>
  );
};
