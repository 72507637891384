import React from "react";

function PoliticallLogoShort(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Web---Verified-Badge---Notifications"
          transform="translate(-369, -288)"
          fill="#FFFFFF">
          <g id="Components/Molecules/Notifications/Verified-Copy" transform="translate(361, 280)">
            <g id="Components/Atoms/Logo/-Icon" transform="translate(8, 8)">
              <path
                d="M15.3494583,14.1978957 L15.3494583,9.40044965 L10.4489166,9.40044965 L10.4489166,6.29828285 L14.4503169,6.29828285 C16.656656,6.29828285 18.4516251,8.09334397 18.4516251,10.2996831 C18.4516251,12.1967353 17.1240844,13.7886427 15.3494583,14.1978957 L15.3494583,14.1978957 Z M13.5510834,16.8636549 C13.5510834,18.1528109 12.5022692,19.201533 11.2132052,19.201533 L10.4489166,19.201533 L10.4489166,16.0993663 L13.5510834,16.0993663 L13.5510834,16.8636549 Z M7.88625308,14.3010834 C6.59718914,14.3010834 5.5483749,13.2523612 5.5483749,11.9632052 L5.5483749,11.1988246 L8.6505417,11.1988246 L8.6505417,14.3010834 L7.88625308,14.3010834 Z M10.4489166,14.3010834 L13.5510834,14.3010834 L13.5510834,11.1989166 L10.4489166,11.1989166 L10.4489166,14.3010834 Z M20.25,10.2996831 C20.25,7.1016926 17.6483995,4.5 14.4503169,4.5 L8.6505417,4.5 L8.6505417,9.40044965 L3.75,9.40044965 L3.75,11.9632052 C3.75,14.2439205 5.60553777,16.0993663 7.88625308,16.0993663 L8.6505417,16.0993663 L8.6505417,21 L11.2132052,21 C13.4940126,21 15.3494583,19.1443702 15.3494583,16.8636549 L15.3494583,16.0993663 L15.3494583,16.0294085 C18.1219951,15.5959465 20.25,13.1918846 20.25,10.2996831 L20.25,10.2996831 Z"
                id="Logo"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PoliticallLogoShort;
