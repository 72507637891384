import React from "react";

import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import { VisuallyHidden } from "@/common/components/VisuallyHidden";

import { getLocalDateFromUtc } from "@/common/services";
import i18n from "@/common/services/i18n";
import { SystemNotificationFragment } from "@/graphql/types";

import SystemNotificationIcon from "../SystemNotificationIcon";
import useStyles from "./NotificationsList.styles";
import { SystemNotificationButtons } from "./SystemNotificationButtons";
import SystemNotificationMoreButton from "./SystemNotificationMoreButton";

const NotificationsListItem = ({
  notification,
  variant,
  clearNotification,
}: {
  notification: SystemNotificationFragment;
  variant?: "small" | "standard";
  clearNotification: (id: string) => void;
}) => {
  const { cx, classes } = useStyles();
  const [hovered, setHovered] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <li className={classes.item} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SystemNotificationIcon notification={notification} />
      <div
        className={classes.content}
        data-cy="NotificationList__system-notification-message"
        data-cy-url={notification.targetLink}>
        {notification.notificationMessage && (
          <p
            onClick={() => {
              notification.targetLink && window?.location.assign(notification.targetLink);
            }}
            dangerouslySetInnerHTML={{
              __html: notification.notificationMessage,
            }}
          />
        )}
        <p
          onClick={() => {
            notification.targetLink && window?.location.assign(notification.targetLink);
          }}
          className={classes.timestamp}>
          {notification.actionTriggered ? (
            <p>{notification.actionTriggered}</p>
          ) : (
            <Moment fromNow locale={i18n.language}>
              {getLocalDateFromUtc(notification.createdAt)}
            </Moment>
          )}
        </p>
        <div className={classes.actions}>
          <SystemNotificationButtons notification={notification} />
        </div>
      </div>
      <div
        role="status"
        style={{ alignSelf: "center" }}
        className={cx(notification.read && classes.statusRead)}>
        {notification.read ? (
          <VisuallyHidden>{t("Notifications.Status.Read")}</VisuallyHidden>
        ) : (
          <>
            <div className={classes.unreadStatus} />
            <VisuallyHidden>{t("Notifications.Status.Unread")}</VisuallyHidden>
          </>
        )}
      </div>
      <div>
        {variant === "standard" && (
          <SystemNotificationMoreButton
            notification={notification}
            hovered={hovered}
            onClearNotification={clearNotification}
          />
        )}
      </div>
    </li>
  );
};

export default NotificationsListItem;
