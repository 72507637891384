import React from "react";

import { List, Theme } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "tss-react/mui";

const useStylesItem = makeStyles()((theme: Theme) => ({
  root: {
    cursor: "pointer",
    "& .MuiListItemIcon-root": {
      fontSize: theme.typography.h6.fontSize,
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        fill: theme.palette.primary.main,
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main + " !important",
    "& svg": {
      fill: theme.palette.common.white + " !important",
      color: theme.palette.common.white + " !important",
    },
    "& span": {
      color: theme.palette.common.white + " !important",
    },
  },
}));

export function MenuList(props: any) {
  return <List {...props} />;
}

export function MenuListItem(props: any) {
  const { classes } = useStylesItem();
  return (
    <ListItem classes={classes} button color="primary" {...props}>
      {props.children}
    </ListItem>
  );
}
