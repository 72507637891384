interface TourStepProps {
  show?: boolean;
  topic: string;
  step: number;
  render: (props: { "data-tour"?: string }) => JSX.Element;
}

const TourStep = ({ show = true, topic, step, render }: TourStepProps) => {
  const props: { "data-tour"?: string } = {};

  if (show) {
    props["data-tour"] = `take-a-tour-${topic}-step-${step}`;
  }
  return render(props);
};

export default TourStep;
