import React, { ReactNode } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  button: {
    borderRadius: "50%",
    display: "flex",
    padding: "12px",
    justifyContent: "center",
    "& svg": {
      fill: "var(--color)",
    },
  },
}));

import { Button, ButtonProps } from "@/common/components/button/Button";

interface IconButtonProps extends ButtonProps {
  children: ReactNode;
}

export const IconButton = ({ children, ...delegated }: IconButtonProps) => {
  const { classes } = useStyles();

  return (
    <Button className={classes.button} {...delegated}>
      {children}
    </Button>
  );
};
