import React from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu, MenuItem, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";

import { SystemNotificationFragment } from "@/graphql/types";

const useStyles = makeStyles()((theme: Theme) => ({
  moreIcon: {
    position: "absolute",
    marginTop: "5px",
    display: "none",
    transition: "all 300ms",
    "&.hover": {
      display: "block",
    },
    "& .iconButton": {
      boxShadow: theme.mode.shadow.popover,
      background: theme.mode.background.light,
      padding: "0px !important",
      width: 32,
      height: 32,
      transition: "box-shadow 200ms",
      " svg": {
        fill: theme.mode.icon.default,
      },

      "&:hover": {
        opacity: 1,
        boxShadow: "0 0 16px 6px rgba(63, 61, 86, 0.08)",
      },
    },
  },
}));

const SystemNotificationMoreButton = ({
  notification,
  hovered,
  onClearNotification,
}: {
  notification: SystemNotificationFragment;
  hovered: boolean;
  onClearNotification: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "notificationItemPopup",
  });

  return (
    <div className={cx(classes.moreIcon, { ["hover"]: hovered })}>
      <IconButton {...bindTrigger(popupState)} size="large" className="iconButton">
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...bindMenu(popupState)}
        onClose={() => {
          popupState?.close();
        }}>
        <MenuItem
          onClick={() => {
            onClearNotification(notification.id);
            popupState?.close();
          }}>
          {t("Notifications.Actions.RemoveSingleNotification")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SystemNotificationMoreButton;
