import Image from "next/image";
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import Link from "@/common/components/Link";
import Spinner from "@/common/components/Spinner";

import { useNotificationsStore } from "@/notifications/hooks";

import EmptyIllustration from "../../images/notifications-illustration.svg";
import useStyles from "./NotificationsList.styles";
import NotificationsListItem from "./NotificationsListItem";

type NotificationsListProps = {
  hidePaginationLinks?: boolean;
  variant?: "small" | "standard";
};

export const NotificationsList = ({
  hidePaginationLinks,
  variant = "standard",
}: NotificationsListProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const SMALL_VARIANT_LIMIT = 5;
  const {
    systemNotifications,
    hasMoreSystemNotificationPages,
    fetchSystemNotifications,
    systemNotificationsLoading,
    totalSystemNotificationsCount,
    fetchNextSystemNotificationsPage,
    clearNotification,
  } = useNotificationsStore();

  const clearSingleNotification = async (id: string) => {
    await clearNotification(id);
    if (systemNotifications?.length === 1) {
      fetchSystemNotifications();
    }
  };

  useEffect(() => {
    fetchSystemNotifications();
  }, []);

  const parsedSystemNotifications =
    variant === "small" ? systemNotifications?.slice(0, SMALL_VARIANT_LIMIT) : systemNotifications;

  if (totalSystemNotificationsCount <= 0) {
    return (
      <div className={classes.emptyWrapper}>
        {systemNotificationsLoading ? (
          <Spinner variant="medium" />
        ) : (
          <>
            <Image src={EmptyIllustration} width="180" height="176" alt="Empty" />
            <h3 className={classes.emptyTitle}>{t("Notifications.EmptyTitle")}</h3>
            <p className={classes.emptySubtitle}>{t("Notifications.EmptySubtitle")}</p>
            <Link href="/">
              <a className={classes.emptyCta}>{t("Notifications.EmptyCta")}</a>
            </Link>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <ol>
        {parsedSystemNotifications?.map((notification) => (
          <NotificationsListItem
            key={`system_notification_${notification.id}`}
            notification={notification}
            variant={variant}
            clearNotification={clearSingleNotification}
          />
        ))}
      </ol>
      {!hidePaginationLinks && hasMoreSystemNotificationPages && (
        <button
          className={cx(classes.paginationLink, classes.paginationLinkBottomMargin)}
          onClick={fetchNextSystemNotificationsPage}>
          {t("Common.SeeMore")}
        </button>
      )}
      {hidePaginationLinks &&
        systemNotifications &&
        systemNotifications?.length > SMALL_VARIANT_LIMIT && (
          <Link href="/notifications">
            <a className={classes.paginationLink}>{t("Notifications.Actions.SeeAll")}</a>
          </Link>
        )}
    </>
  );
};
