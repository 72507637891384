import React, { useState, MouseEvent, ReactNode, ButtonHTMLAttributes } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Popover, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { IconButton } from "@/common/components/IconButton";

export const useStyles = makeStyles()((theme: Theme) => ({
  popover: {
    backgroundColor: theme.mode.background.menu.default,
    boxShadow: theme.mode.shadow.dialog,
    "& .MuiList-root": {
      "& .MuiListItem-root": {
        "&:hover": {
          backgroundColor: theme.mode.background.menu.hover,
        },
        "& span": {
          color: theme.mode.text.heading,
        },
      },
    },
  },
}));

type MoreActionsButtonProps = {
  IconButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  variant?: "secondary" | "inline-secondary";
  children: (handleClose: () => void) => ReactNode;
  clickInterceptor?: () => void;
};

export const MoreActionsButton = ({
  IconButtonProps,
  variant,
  children,
  clickInterceptor,
}: MoreActionsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { classes } = useStyles();
  const open = !!anchorEl;
  const id = open ? "more-btn-popover" : undefined;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    clickInterceptor ? clickInterceptor() : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        {...(IconButtonProps || {})}
        variant={variant ?? "secondary"}
        onClick={handleClick}
        data-cy="MoreActionsButton__toggle">
        <MoreHorizIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: classes.popover }}>
        {children(handleClose)}
      </Popover>
    </>
  );
};
