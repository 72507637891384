import React from "react";

import { List, ListItem, ListItemText } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import { MoreActionsButton } from "@/common/components/MoreActionsButton";

import { useNotificationsStore } from "@/notifications/hooks";

const useStyles = makeStyles()((theme) => ({
  moreMenuIconButton: {
    padding: 0,
    background: "none",
    minHeight: 0,

    "& svg": {
      color: theme.mode.icon.light,
    },
  },
  optionItem: {
    "&:hover": {
      backgroundColor: theme.mode.background.default,
    },
  },
  text: {
    "& span": {
      color: theme.mode.text.heading,
    },
  },
}));

export const Actions = () => {
  const { clearAll } = useNotificationsStore();
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <MoreActionsButton
      IconButtonProps={{
        className: classes.moreMenuIconButton,
      }}>
      {(closeMenu) => (
        <>
          <List>
            <ListItem
              button
              onClick={() => {
                clearAll();
                closeMenu();
              }}
              className={classes.optionItem}
              data-cy="NotificationsActions__clear-all">
              <ListItemText
                primary={t("Notifications.Actions.ClearAll")}
                className={classes.text}
              />
            </ListItem>
          </List>
        </>
      )}
    </MoreActionsButton>
  );
};
