import React, { HTMLAttributes } from "react";

export const HamburgerIcon = (props: HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h32v32H0z" />
        <path
          d="M6.667 6.667h18.666a1.333 1.333 0 1 1 0 2.666H6.667a1.333 1.333 0 1 1 0-2.666zm0 8h18.666a1.333 1.333 0 1 1 0 2.666H6.667a1.333 1.333 0 1 1 0-2.666zm0 8h18.666a1.333 1.333 0 1 1 0 2.666H6.667a1.333 1.333 0 1 1 0-2.666z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
