import React, { HTMLAttributes } from "react";

export const CommunityIcon = (props: HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h32v32H0z" />
        <path
          d="M25.667 4H21a2.333 2.333 0 0 0-2.333 2.333V11A2.333 2.333 0 0 0 21 13.333h4.667A2.333 2.333 0 0 0 28 11V6.333A2.333 2.333 0 0 0 25.667 4zM11 18.667H6.333A2.333 2.333 0 0 0 4 21v4.667A2.333 2.333 0 0 0 6.333 28H11a2.333 2.333 0 0 0 2.333-2.333V21A2.333 2.333 0 0 0 11 18.667zm14.667 0H21A2.333 2.333 0 0 0 18.667 21v4.667A2.333 2.333 0 0 0 21 28h4.667A2.333 2.333 0 0 0 28 25.667V21a2.333 2.333 0 0 0-2.333-2.333zm-17-5.334a4.667 4.667 0 1 0 0-9.333 4.667 4.667 0 0 0 0 9.333z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
