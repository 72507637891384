import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState, ReactNode } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Paper, Avatar, IconButton, Theme, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Drawer } from "@/common/components/Drawer/Drawer";
import { MenuList, MenuListItem } from "@/common/components/MenuList";
import TourStep from "@/tour/components/TourStep";

import { useAuthenticationStore } from "@/authentication/hooks";

import { MOBILE_PLAYSTORE_URL } from "@/common/constants";
import { AppBarActions } from "@/common/models/AppBarActions";
import { MainAppRoute } from "@/common/models/MainAppRoute";
import {
  mainRoutes,
  secondaryNavItems,
  maybeGetCdnPath,
  localizedAppstoreURL,
} from "@/common/services";

import useStyles from "./Sidebar.styles";

type SidebarProps = {
  open: boolean;
  hideLeftSideMenu?: boolean;
  onAction: (action: AppBarActions) => void;
  onClose: () => void;
};

type SidebarWrapperProps = {
  open: boolean;
  hideLeftSideMenu?: boolean;
  onClose: () => void;
  children?: ReactNode;
  classes: Record<"mobileNav" | "nav", string>;
};

const SidebarWrapper = ({
  children,
  open,
  hideLeftSideMenu,
  classes,
  onClose,
}: SidebarWrapperProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"), { noSsr: true });

  useEffect(() => {
    return setMenuOpen(open);
  }, [open]);

  const handleMenuClose = () => {
    setMenuOpen(false);
    onClose();
  };

  return (
    <>
      {isMobile ? (
        <Drawer
          open={menuOpen}
          direction="right"
          onClose={handleMenuClose}
          onOpen={() => setMenuOpen(true)}>
          <nav className={classes.mobileNav}>{children}</nav>
        </Drawer>
      ) : (
        hideLeftSideMenu || (
          <Paper component="nav" className={classes.nav} elevation={0}>
            {children}
          </Paper>
        )
      )}
    </>
  );
};

export const Sidebar = ({ onClose, onAction, ...props }: SidebarProps) => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"), { noSsr: true });
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { session } = useAuthenticationStore();
  const { avatarPredefinedName, avatar } = session?.user || {};
  const avatarSrc = avatarPredefinedName
    ? maybeGetCdnPath(`/images/avatars/${avatarPredefinedName}.png`)
    : avatar?.thumb;

  const exactPathnames = ["/", "/community", "/news"];
  const primaryNavItems: MainAppRoute[] = mainRoutes.filter((item: MainAppRoute) => {
    if (item.id === "home") return !isMobile;
    return true;
  });

  const handleProfileClick = () => {
    router.push(session?.user?.url as string);
    onClose();
  };

  const handleSecondaryAction = (action: AppBarActions) => {
    onAction(action);
    onClose();
  };

  const isSelected = (item: MainAppRoute): boolean => {
    if (item.link === "/" && router.pathname === "/community") return true;
    return exactPathnames.includes(item.link)
      ? item.link === router.pathname
      : router.pathname.startsWith(item.link);
  };

  return (
    <SidebarWrapper
      {...props}
      key={isMobile ? "mobile" : "desktop"}
      onClose={onClose}
      classes={classes}>
      {isMobile && (
        <div onClick={handleProfileClick} className={classes.navHeader}>
          <Avatar className={classes.navProfileAvatar} src={avatarSrc}>
            {session?.user?.initials}
          </Avatar>
          <div className={classes.navProfileInfo}>
            <span className={classes.navProfileLabel}>{session?.user?.displayName}</span>
            <span className={classes.navProfileCaption}>{t("Profile.SeeYourProfile")}</span>
          </div>
          <IconButton classes={{ root: classes.closeButton }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <MenuList disablePadding classes={{ root: classes.navItems }}>
        {primaryNavItems.map((item: MainAppRoute) => (
          <Link key={item.label} href={item.link} passHref>
            <MenuListItem
              className={classes.navItem}
              button
              selected={isSelected(item)}
              color="primary">
              <item.icon className={classes.navItemIcon} />
              <TourStep
                show={!isMobile && item.id === "groups"}
                topic="discover"
                step={1}
                render={(props) => (
                  <span className={classes.navItemLabel} {...props}>
                    {t(item.label)}
                  </span>
                )}
              />
            </MenuListItem>
          </Link>
        ))}
      </MenuList>
      {!isMobile && (
        <>
          <div className={classes.appStoreLinks}>
            <Link href={localizedAppstoreURL(i18n.language)} passHref>
              <Image
                className={classes.link}
                src={`/images/appstore/${i18n.language}/apple_store.svg`}
                width={isMobile ? 183 : 235}
                height={isMobile ? 62 : 80}></Image>
            </Link>
            <Link href={MOBILE_PLAYSTORE_URL} passHref>
              <Image
                className={classes.link}
                src={`/images/appstore/${i18n.language}/google_store.svg`}
                width={isMobile ? 183 : 235}
                height={isMobile ? 62 : 80}></Image>
            </Link>
          </div>
        </>
      )}

      {isMobile && (
        <>
          <MenuList disablePadding classes={{ root: classes.secondaryNavItems }}>
            {secondaryNavItems.map((item) => (
              <MenuListItem
                key={item.action}
                className={classes.secondaryNavItem}
                onClick={() => handleSecondaryAction(item.action)}>
                <item.icon className={classes.secondaryNavItemIcon} />
                <span className={classes.secondaryNavItemLabel}>{t(item.label)}</span>
              </MenuListItem>
            ))}
          </MenuList>
          <div className={classes.appStoreLinks}>
            <Link
              className="plausible-event-name=iosButtonClick"
              href={localizedAppstoreURL(i18n.language)}
              passHref>
              <Image
                src={`/images/appstore/${i18n.language}/apple_store.svg`}
                width={isMobile ? 183 : 235}
                height={isMobile ? 62 : 80}></Image>
            </Link>
            <Link
              className="plausible-event-name=androidButtonClick"
              href={MOBILE_PLAYSTORE_URL}
              passHref>
              <Image
                src={`/images/appstore/${i18n.language}/google_store.svg`}
                width={isMobile ? 183 : 235}
                height={isMobile ? 62 : 80}></Image>
            </Link>
          </div>
        </>
      )}
    </SidebarWrapper>
  );
};
